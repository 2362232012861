import {Skeleton} from '@mui/material';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';

export const RepricerItemsLoader = () => {
  return [1, 2, 3, 4, 5, 6, 7].map((_, idx) => (
    <MTableRow key={idx}>
      <MTableCell align="center" colSpan={6}>
        <Skeleton height={38} width="100%" />
      </MTableCell>
    </MTableRow>
  ));
};
