import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {ITEMS_LIST_OFFSET} from '@src/pages/repricer/consts';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {ProductFilters} from './product-filters/product-filters';
import {ProductsTable} from './products-table/products-table';
import {TSelectedProductFilters} from '../../../../types/products';

import {useEmotionWrapper} from './add-items.s';

export const AddProducts = observer(() => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts, repricer} = useStore();

  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const [products, setProducts] = useState<TMpAccountItem[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<TSelectedProductFilters>({
    search: '',
    shops: [],
    categories: [],
    vendors: [],
    groups: [],
  });
  const [pageParams, setPageParams] = useState({offset: 0, limit: ITEMS_LIST_OFFSET, count: 0});

  const onLoadProducts = async (offset: number) => {
    const {search, shops, categories, vendors, groups} = selectedFilters;

    return await repricer.getItemsByAccountId({
      marketPlaceAccountId: mpAccounts.current?.id,
      shopsIds: shops,
      categoriesId: categories,
      vendorsIds: vendors,
      groupIds: groups,
      offset: offset ? offset : pageParams.offset,
      limit: pageParams.limit,
      search,
    });
  };

  const loadProducts = async (offset: number = 0) => {
    setIsLoadingProducts(true);
    try {
      const res = await onLoadProducts(offset);

      setProducts(res?.items as unknown as TMpAccountItem[]);
      setPageParams({offset: offset, limit: res?.limit, count: res?.count});
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const loadMoreProducts = async (offset: number) => {
    if (offset < pageParams.count) {
      try {
        const res = await onLoadProducts(offset);

        setProducts([...products, ...(res?.items as unknown as TMpAccountItem[])]);
        setPageParams({offset: res?.offset, limit: res?.limit, count: res?.count});
      } catch (e) {
        console.log('e', e);
      }
    }
  };

  useEffect(() => {
    if (mpAccounts.current?.id) {
      loadProducts();
    }
  }, [JSON.stringify(selectedFilters), mpAccounts.current?.id]);
  return (
    <div className={classes.root}>
      <ProductFilters selectedFilters={selectedFilters} onChangeSelectedFilters={setSelectedFilters} />
      <div className={classes.tableContainer}>
        <ProductsTable
          products={products}
          isLoadingProducts={isLoadingProducts}
          offset={pageParams.offset}
          loadProducts={loadMoreProducts}
        />
      </div>
    </div>
  );
});
