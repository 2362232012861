import {type FC, useLayoutEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import InsightsIcon from '@mui/icons-material/Insights';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Tooltip} from '@mui/material';
import {NoImageIcon} from '@src/components/common/icons/components/no-image';
import {Link} from '@src/components/common/link/link';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {getRouteSettings} from '@src/shared/const/router';
import {insertSeparatorToNumberOrString} from '@src/shared/utils/insert-separator-to-number-or-string';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {PriceUpdaterTooltip} from '../price-updater-tooltip/price-updater-tooltip';
import {RepricerItemDetails} from '../repricer-item-details/repricer-item-details';

import {useEmotionWrapper} from './product-settings-row.s';

type TRepricerItemWithId = TRepricerItem & {id: number};
type TUpdateProductPriceOnShop = ({
  shopId,
  price,
  type,
}: {
  shopId?: number;
  price: number;
  type: 'price' | 'minPrice' | 'maxPrice';
}) => void;

interface IProductSettingsRow {
  product: TRepricerItem | TMpAccountItem;
  collectPriceUpdateFunctions: (updateFunction: () => Promise<void>) => void;
}

export const ProductSettingsRow: FC<IProductSettingsRow> = observer(({product, collectPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts} = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAnalytics, setIsOpenAnalytics] = useState(false);
  const [isErrorLoadImage, setIsErrorLoadImage] = useState(false);
  const [newProductData, setNewProductData] = useState<TRepricerItemWithId | TMpAccountItem>({} as TRepricerItemWithId);

  useLayoutEffect(() => {
    setNewProductData(product as TRepricerItemWithId);
  }, [product]);

  const isRepricerItem = useMemo(() => {
    return 'bestMarketOffer' in newProductData;
  }, [newProductData.sku]);

  const getMinMaxPrice = (shops: TRepricerItem['shops']) => {
    if (!shops) return {price: '', minPrice: '', maxPrice: ''};

    const minPriceCurrent = Math.min(...shops.map((s) => s.price));
    const maxPriceCurrent = Math.max(...shops.map((s) => s.price));
    const minMinPrice = Math.min(...shops.map((s) => (s.minPrice === null ? 0 : s.minPrice)));
    const maxMinPrice = Math.max(...shops.map((s) => (s.minPrice === null ? 0 : s.minPrice)));
    const minMaxPrice = Math.min(...shops.map((s) => (s.maxPrice === null ? 0 : s.maxPrice)));
    const maxMaxPrice = Math.max(...shops.map((s) => (s.maxPrice === null ? 0 : s.maxPrice)));

    if (shops.length === 1) {
      return {
        price: shops[0].price,
        minPrice: shops[0].minPrice,
        maxPrice: shops[0].maxPrice,
      };
    } else {
      return {
        price: maxPriceCurrent === minPriceCurrent ? maxPriceCurrent : `${minPriceCurrent} - ${maxPriceCurrent}`,
        minPrice: minMinPrice === maxMinPrice ? minMinPrice : `${minMinPrice} - ${maxMinPrice}`,
        maxPrice: minMaxPrice === maxMaxPrice ? minMaxPrice : `${minMaxPrice} - ${maxMaxPrice}`,
      };
    }
  };

  const productHasOneShop = newProductData?.shops?.length === 1;

  const updateProductPriceOnShop: TUpdateProductPriceOnShop = (updateData) => {
    const {shopId, price, type} = updateData;
    const newProductsOnStrategy = {...newProductData};
    if (shopId) {
      const findShopIndex = newProductsOnStrategy.shops.findIndex((shop) => shop.shopId === shopId);

      if (findShopIndex !== -1) {
        newProductsOnStrategy.shops[findShopIndex][type] = price;
      }
      setNewProductData({...newProductsOnStrategy});
    } else {
      const updatedShops = newProductsOnStrategy.shops.map((shop) => {
        return {...shop, [type]: price};
      });

      // @ts-ignore
      setNewProductData({...newProductsOnStrategy, shops: updatedShops as TRepricerItemWithId['shops']});
    }
  };

  const formatPriceToString = (price: number | null) => {
    return price ? `${insertSeparatorToNumberOrString(price)} ₽` : '–';
  };

  //@ts-ignore
  if (isRepricerItem && product.isActive === false) return null

  return (
    <>
      <MTableRow sx={{backgroundColor: isOpen ? COLORS_REDESIGN.LIGHT_GREY : 'transparent'}}>
        <MTableCell align="center">
          <RepricerItemDetails
            product={isRepricerItem ? (newProductData as TRepricerItem) : null}
            isOpenAnalytics={isOpenAnalytics}
            setIsOpenAnalytics={setIsOpenAnalytics}
          />
          {!productHasOneShop ? (
            <MIconButton onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MIconButton>
          ) : (
            <div className={classes.collapseNoButton} />
          )}
        </MTableCell>
        <MTableCell>
          <div className={classes.nameCell}>
            {isErrorLoadImage ? (
              <div className={classes.noImage}>
                <NoImageIcon color={COLORS_REDESIGN.DARK_GREY} size="32" />
              </div>
            ) : (
              <img
                onError={() => setIsErrorLoadImage(true)}
                loading="lazy"
                width={34}
                height={45}
                src={product.picture || ''}
              />
            )}
            <div className={classes.productInfo}>
              <span className={classes.productName}>{product.name}</span>
              <span className={classes.productSku}>
                SKU: {product.sku} · {product.category}
              </span>
            </div>
          </div>
        </MTableCell>
        <MTableCell>
          {isRepricerItem ? (
            <MIconButton onClick={() => setIsOpenAnalytics(true)} size="small">
              <InsightsIcon sx={{fontSize: 20}} />
            </MIconButton>
          ) : (
            <div className={classes.collapseNoButton} />
          )}
        </MTableCell>
        <MTableCell align="center">
          <PriceUpdaterTooltip
            collectPriceUpdateFunctions={collectPriceUpdateFunctions}
            updateProductPriceOnShop={updateProductPriceOnShop}
            shop={newProductData.shops?.[0]}
            type="price"
            isAllShops={productHasOneShop ? false : true}
            productId={newProductData?.id}
          >
            {productHasOneShop
              ? insertSeparatorToNumberOrString(newProductData.shops[0].price ? newProductData.shops[0].price : product.price)
              : getMinMaxPrice(newProductData.shops).price}{' '}
            {''}₽
          </PriceUpdaterTooltip>
        </MTableCell>
        <MTableCell align="center">
          <PriceUpdaterTooltip
            collectPriceUpdateFunctions={collectPriceUpdateFunctions}
            updateProductPriceOnShop={updateProductPriceOnShop}
            shop={newProductData.shops?.[0]}
            type="minPrice"
            isAllShops={productHasOneShop ? false : true}
            productId={newProductData?.id}
          >
            {productHasOneShop
              ? insertSeparatorToNumberOrString(newProductData.shops?.[0].minPrice || 0)
              : getMinMaxPrice(newProductData.shops).minPrice}{' '}
            {''}₽
          </PriceUpdaterTooltip>
        </MTableCell>
        <MTableCell align="center">
          <PriceUpdaterTooltip
            collectPriceUpdateFunctions={collectPriceUpdateFunctions}
            updateProductPriceOnShop={updateProductPriceOnShop}
            shop={newProductData.shops?.[0]}
            type="maxPrice"
            isAllShops={productHasOneShop ? false : true}
            productId={newProductData?.id}
          >
            {productHasOneShop
              ? insertSeparatorToNumberOrString(newProductData.shops?.[0].maxPrice || 0)
              : getMinMaxPrice(newProductData.shops).maxPrice}{' '}
            {''}₽
          </PriceUpdaterTooltip>
        </MTableCell>
        {/* <MTableCell>
          <MButton disabled={!isRepricerItem} startIcon={<AddCircleOutlineIcon />} variant="text">
            Отслеживать
          </MButton>
        </MTableCell> */}
      </MTableRow>
      {isOpen &&
        !productHasOneShop &&
        newProductData.shops.map((s) => (
          <MTableRow sx={{backgroundColor: isOpen ? COLORS_REDESIGN.LIGHT_GREY : 'transparent'}} key={s.shopId}>
            <MTableCell align="center">
              <div className={classes.collapseNoButton} />
            </MTableCell>
            <MTableCell>
              <div className={classes.nameCell}>
                <img width={34} height={45} src={product.picture || ''} />
                <div className={classes.productInfo}>
                  <span className={classes.productName}>{product.name}</span>
                  <span style={{fontWeight: 700}} className={classes.productSku}>
                    {s.shopName}{' '}
                  </span>
                </div>
              </div>
            </MTableCell>
            <MTableCell />
            <MTableCell align="center">
              <Tooltip
                title={
                  mpAccounts.current?.onlyDefaultPriceEnabled ? (
                    <>
                      Редактирование доступно только при активной модели{' '}
                      <Link href={`${getRouteSettings()}/price-monitoring`}>"От цен в магазинах"</Link>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <div>
                  <PriceUpdaterTooltip
                    disabled={mpAccounts.current?.onlyDefaultPriceEnabled}
                    collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                    updateProductPriceOnShop={updateProductPriceOnShop}
                    shop={s}
                    type="price"
                    productId={newProductData?.id}
                  >
                    {formatPriceToString(s.price)}
                  </PriceUpdaterTooltip>
                </div>
              </Tooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={s}
                type="minPrice"
                productId={newProductData?.id}
              >
                {formatPriceToString(s.minPrice)}
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={s}
                type="maxPrice"
                productId={newProductData?.id}
              >
                {formatPriceToString(s.maxPrice)}
              </PriceUpdaterTooltip>
            </MTableCell>
            {/* <MTableCell></MTableCell> */}
          </MTableRow>
        ))}
    </>
  );
});
