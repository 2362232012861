import {cast, flow, types} from 'mobx-state-tree';
import api from '../../shared/configs/axiosConfig';

import {REPRICER_STRATEGY} from '@src/shared/enums/repricer-strategy';
import {handlingConsoleRequestError} from '@src/shared/utils/handlingConsoleRequestError';
import {
  TCreateStrategyPayload,
  TRepricerStrategy,
  TRepricerStrategyGetItemsPayload,
  TRepricerStrategyUpdatePayload,
} from './types';

import {TGetAccountItemsRequest, TMpAccountItem, TMpItemShop} from '../market-place-account-item/types';

export const RepricerStrategy = types.model('RepricerStrategies', {
  id: types.identifierNumber,
  name: types.string,
  enabled: types.boolean,
  type: types.enumeration([REPRICER_STRATEGY.DEFAULT, REPRICER_STRATEGY.PLACE]),
  params: types.model('RepricerStrategyParams', {
    place: types.maybeNull(types.number),
  }),
  isAuto: types.boolean,
  minPricePct: types.maybeNull(types.number),
  maxPricePct: types.maybeNull(types.number),
});

export const RepricerStore = types
  .model('UserStoreModel', {
    list: types.optional(types.array(RepricerStrategy), []),
    summaryRepricerItemIds: types.map(
      types.model('SummaryRepricerItemIds', {
        itemIds: types.array(types.number),
      })
    ),
  })
  .actions((self) => ({
    getItemsByAccountId: flow(function* (params: TGetAccountItemsRequest) {
      const {
        marketPlaceAccountId,
        offset,
        limit = 30,
        categoriesId = [],
        vendorsIds = [],
        shopsIds = [],
        groupIds = [],
        search = '',
      } = params;

      try {
        const itemsData = yield api.post(`/item/list`, {
          pagination: {
            limit: limit ? limit : 30,
            offset: offset,
          },
          filters: {
            vendorIds: vendorsIds?.length ? vendorsIds : undefined,
            categoryIds: categoriesId?.length ? categoriesId : undefined,
            shopIds: shopsIds?.length ? shopsIds : undefined,
            groupIds: groupIds?.length ? groupIds : undefined,
            strategies: undefined,
            haveIndividualStrategy: false,
            isInStock: null,
            search: search,
          },
          marketPlaceAccountId: marketPlaceAccountId,
        });

        const {data} = itemsData;

        return {
          items: data.items.map((item: TMpAccountItem) => {
            return {
              ...item,
              id: item.id,
              name: item.name,
              shops: item.shops.map((shop: TMpItemShop) => ({
                ...shop,
                price: shop.price / 100,
                minPrice: shop.minPrice ? shop.minPrice / 100 : null,
                maxPrice: shop.maxPrice ? shop.maxPrice / 100 : null,
              })),
              picture: item.picture,
              category: item.category,
              vendor: item.vendor,
              sku: item.sku,
              price: item.price / 100,
            };
          }),
          count: data.count,
          limit: data.limit,
          offset: data.offset,
        };
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    createStrategy: flow(function* (strategy: TCreateStrategyPayload) {
      try {
        const r = yield api.post<{strategyId: number}>('/repricer/strategies', {...strategy});

        return r.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getStrategies: flow(function* (marketPlaceAccountId: number) {
      try {
        const r = yield api.get<TRepricerStrategy[]>('/repricer/strategies', {params: {marketPlaceAccountId}});

        let objMap = {};

        r.data.forEach((element: {itemIds: number[]; id: number}) => {
          objMap = {
            ...objMap,
            [element.id]: {
              itemIds: element.itemIds,
            },
          };
        });

        self.summaryRepricerItemIds = cast(objMap);
        self.list = r.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    deleteStrategy: flow(function* (strategyId: number) {
      try {
        const findStrategyIndex = self.list.findIndex((item) => item.id === strategyId);
        yield api.delete<TRepricerStrategy[]>(`/repricer/strategies/${strategyId}`);

        const list = [...self.list];

        if (findStrategyIndex !== -1) {
          list.splice(findStrategyIndex, 1);
        }

        self.list = cast([...list]);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateStrategy: flow(function* (strategy: TRepricerStrategyUpdatePayload, marketPlaceAccountId: number) {
      try {
        const findStrategyIndex = self.list.findIndex((item) => item.id === strategy.id);
        yield api.patch<TRepricerStrategy[]>(`/repricer/strategies/${strategy.id}`, {
          ...strategy,
          marketPlaceAccountId,
        });

        if (findStrategyIndex !== -1) {
          self.list[findStrategyIndex] = {
            ...self.list[findStrategyIndex],
            ...strategy,
          };
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getStrategyItems: flow(function* ({
      strategyId,
      marketPlaceAccountId,
      pageParams,
    }: TRepricerStrategyGetItemsPayload) {
      try {
        const res = yield api.post<TRepricerStrategy[]>(`/repricer/strategies/${strategyId}/items/search`, {
          strategyId,
          marketPlaceAccountId,
          pagination: pageParams,
          filters: {},
          orders: [],
        });
        return res.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    addItemsToStrategy: flow(function* ({strategyId, itemIds}: {strategyId: number; itemIds: number[]}) {
      try {
        yield api.post(`/repricer/strategies/${strategyId}/items`, {
          itemIds,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    //FIXME: addItemsToStrategy
    updateMinPriceShopItem: flow(function* ({
      shopId,
      minPrice,
      productShopId,
    }: {
      shopId?: number;
      minPrice: number;
      productShopId: number;
    }) {
      try {
        yield api.patch(`/repricer/items/${productShopId}/min_price`, {
          shopId: shopId,
          minPrice: minPrice,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateMaxPriceShopItem: flow(function* ({
      shopId,
      maxPrice,
      productShopId,
    }: {
      shopId?: number;
      maxPrice: number;
      productShopId: number;
    }) {
      try {
        self.list.findIndex((item) => item.id === productShopId);
        yield api.patch(`/repricer/items/${productShopId}/max_price`, {
          shopId: shopId,
          maxPrice: maxPrice,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updatePriceShopItem: flow(function* ({shopId, price, itemId}: {shopId?: number; price: number; itemId: number}) {
      try {
        yield api.patch(`/shops/${shopId}/items/${itemId}/price`, {
          shopId: shopId,
          price: price,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    uploadFileFeed: flow(function* ({file, marketPlaceAccountId}: {file: File; marketPlaceAccountId: number}) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('marketPlaceAccountId', marketPlaceAccountId.toString());

        const r = yield api.post('repricer/items/upload', formData);

        if (r.status === 200) {
          return r;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    deleteItemsFromStrategy: flow(function* ({strategyId, ids}: {strategyId: number; ids: number[]}) {
      try {
        const r = yield api.delete(`repricer/strategies/${strategyId}/items`, {params: {ids: ids.join(',')}});

        if (r.status === 200) {
          return r;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
  }));
