import {flow, types} from 'mobx-state-tree';
import api from '@shared/configs/axiosConfig';

import {TARIFFS_TYPES} from '@shared/enums/tariffs';
import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {SUBSCRIPTION_DURATION, TSubscriptionDuration} from '@src/shared/enums/subscription-duration';
import {TTariffResponse} from './types';

import {getDiscountDurationByNumber} from './utils/get-discount-duration-by-number';

export const Tariff = types.model('Tariff', {
  id: types.number,
  name: types.string,
  type: types.enumeration([
    TARIFFS_TYPES.START,
    TARIFFS_TYPES.PROFESSIONAL,
    TARIFFS_TYPES.LEADER,
    TARIFFS_TYPES.ENTERPRISE,
    TARIFFS_TYPES.ECONOMY,
  ]),
  price: types.number,
  priceYear: types.number,
  discountDuration: types.maybeNull(types.enumeration(Object.values(SUBSCRIPTION_DURATION))),
  discountMonths3Pct: types.number,
  discountMonths6Pct: types.number,
  discountYearPct: types.number,
  priceMonths3: types.number,
  priceMonths6: types.number,
  revenueTo: types.maybeNull(types.number),
  personalDiscount: types.number,
  available: types.boolean,
  maxRepricerItems: types.number,
  maxBidderItems: types.maybeNull(types.number),
});

export const TariffsStore = types
  .model('TariffsStore', {
    list: types.optional(types.array(Tariff), []),
  })
  .actions((self) => ({
    getTariffsList: flow(function* (marketPlaceAccountId: number) {
      try {
        const list = yield api
          .get(`${import.meta.env.WEB_API_BASE_URL}/tariff/list`, {
            params: {
              marketPlaceAccountId,
            },
          })
          .then((r) => r.data);

        self.list = list.map((t: TTariffResponse) => {
          return {
            ...t,
            discountDuration: getDiscountDurationByNumber(t.discountDuration),
          };
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    createInvoice: flow(function* (mpAccountId: number, tariffId: number, subscriptionDuration: TSubscriptionDuration) {
      try {
        const r = yield api.post(`${import.meta.env.WEB_API_BASE_URL}/tariff/invoice/create`, {
          marketPlaceAccountId: mpAccountId,
          subscriptionDuration,
          tariffId,
        });
        return r.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    cancelSubscription: flow(function* (marketPlaceAccountId: number, reason?: string) {
      try {
        const r = yield api
          .post(`${import.meta.env.WEB_API_BASE_URL}/tariff/subscription/cancel`, {
            marketPlaceAccountId,
            reason: reason ? reason : '',
          })
          .then((r) => r.data);
        return r;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
  }));
