import {FC, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Tooltip} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Switch from '@mui/material/Switch';
import {useConfirmationDialog} from '@src/components';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';
import {TRepricerStrategy} from '@src/stores/repricer/types';

import {StrategyLabel} from '../strategy-label/strategy-label';

interface IStrategyRow {
  strategy: TRepricerStrategy;
}

export const StrategyRow: FC<IStrategyRow> = observer(({strategy}) => {
  const {repricer, mpAccounts} = useStore();
  const navigate = useNavigate();
  const {getConfirmation} = useConfirmationDialog();

  const [isLoadingIsAuto, setIsLoadingIsAuto] = useState(false);
  const [isLoadingEnable, setIsLoadingEnable] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onDelete = async (strategyId: number) => {
    const confirm = await getConfirmation({
      title: 'Подтвердите удаление стратегии',
      message: `Вы дествительно хотите удалить стратегию ${strategy.name}?`,
    });

    if (confirm) {
      setIsLoadingDelete(true);
      await repricer.deleteStrategy(strategyId).finally(() => setIsLoadingDelete(false));
      mpAccounts.current?.id && (await repricer.getStrategies(mpAccounts.current?.id));
    }
  };

  const onChangeEnabled = async (strategyId: number, enabled: boolean) => {
    if (mpAccounts.current?.id) {
      setIsLoadingEnable(true);
      await repricer
        .updateStrategy({id: strategyId, enabled}, mpAccounts.current?.id)
        .finally(() => setIsLoadingEnable(false));
    }
  };

  const changeIsAutoStrategy = async (strategyId: number, isAuto: boolean) => {
    if (mpAccounts.current?.id) {
      setIsLoadingIsAuto(true);
      await repricer
        .updateStrategy({id: strategyId, isAuto}, mpAccounts.current?.id)
        .then(() => toast.success(`Автоматическое управление ${isAuto ? 'включено' : 'отключено'}`))
        .catch(() => null)
        .finally(() => setIsLoadingIsAuto(false));
    }
  };

  return (
    <>
      <MTableRow key={strategy.name}>
        <MTableCell>
          <MButton onClick={() => navigate(`/repricer/${strategy.id}`)} variant="text">
            {strategy.name}
          </MButton>
        </MTableCell>
        <MTableCell>
          <StrategyLabel strategy={strategy.type} />
        </MTableCell>
        <MTableCell align="right">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end', position: 'relative'}}>
            {strategy.enabled && (
              <Tooltip title="Автоматическое управление стратегией" arrow>
                <Switch
                  disabled={isLoadingIsAuto}
                  onChange={() => changeIsAutoStrategy(strategy.id, !strategy.isAuto)}
                  checked={strategy.isAuto}
                />
              </Tooltip>
            )}
            <Tooltip title={strategy.enabled ? 'Остановить стратегию' : 'Включить стратегию'} arrow>
              <div>
                <MButton disabled={isLoadingEnable} onClick={() => onChangeEnabled(strategy.id, !strategy.enabled)}>
                  {isLoadingEnable ? (
                    <CircularProgress size="20px" color="primary" />
                  ) : strategy.enabled ? (
                    <PauseIcon sx={{color: COLORS_REDESIGN.ORANGE}} />
                  ) : (
                    <PlayArrowIcon sx={{color: COLORS_REDESIGN.GREEN}} />
                  )}
                </MButton>
              </div>
            </Tooltip>
            <MButton disabled={isLoadingDelete} onClick={() => onDelete(strategy.id)}>
              {isLoadingDelete ? (
                <CircularProgress size="20px" color="primary" />
              ) : (
                <DeleteOutlineIcon sx={{color: COLORS_REDESIGN.DARK_GREY}} />
              )}
            </MButton>
          </div>
        </MTableCell>
      </MTableRow>
    </>
  );
});
