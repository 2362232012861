import {useContext} from 'react';

import {StrategyContext} from '../context/create-strategy';

export const useStrategy = () => {
  const {productsOnStrategy, onSelectProduct, strategy, onChangeStrategy, deleteProductIds, initialRepricerItems} =
    useContext(StrategyContext);

  return {
    productsOnStrategy,
    onSelectProduct,
    onChangeStrategy,
    strategy,
    deleteProductIds,
    initialRepricerItems,
  };
};
