import {COLORS} from '@src/shared/const/appPalette';
import {emotionWrapper} from '@src/shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: COLORS.WHITE,
    overflowY: 'hidden',
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.WHITE,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 9px 22px -7px rgba(34, 60, 80, 0.2)',
    marginBottom: 15,
    padding: '10px 40px',
  },
  content: {
    padding: '25px 40px',
    maxHeight: '100%',
  },
  modalEnter: {
    top: '100%',
  },
  modalEnterActive: {
    top: 0,
    transition: 'top 250ms ease-out',
  },
  modalExit: {
    top: 0,
  },
  modalExitActive: {
    top: '100%',
    transition: 'top 250ms ease-out',
  },
}));
