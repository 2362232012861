import {FC, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';

import {TableContainer} from '@mui/material';
import {Loader} from '@src/components/common/loader/loader';
import {MTable} from '@src/components/redesign/mui-components/common/table/table';
import {MTableBody} from '@src/components/redesign/mui-components/common/table/table-body';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableHead} from '@src/components/redesign/mui-components/common/table/table-head';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useInfiniteLoader} from '@src/hooks/useInfiniteLoader';
import {ITEMS_LIST_OFFSET} from '@src/pages/repricer/consts';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {ProductRow} from '../product-row/product-row';
import {ProductRowLoader} from '../product-row-loader/product-row-loader';

import {useEmotionWrapper} from './product-table.s';

interface IProductsTable {
  products: TMpAccountItem[];
  isLoadingProducts: boolean;
  loadProducts: (offset: number) => Promise<void>;
  offset: number;
}

export const ProductsTable: FC<IProductsTable> = observer(({products, isLoadingProducts, loadProducts, offset}) => {
  const {classes} = useEmotionWrapper();
  const tableEl = useRef<HTMLDivElement | null>(null);
  const {loading} = useInfiniteLoader({
    refEl: tableEl,
    loadMore: () => loadProducts(offset + ITEMS_LIST_OFFSET),
    offset,
  });

  const scrollToTop = () => {
    tableEl.current?.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToTop();
  }, [isLoadingProducts]);

  return (
    <TableContainer className={classes.tableRoot} ref={tableEl}>
      <MTable size="small" stickyHeader>
        <MTableHead>
          <MTableRow>
            <MTableCell>
              <MTypography sx={{fontWeight: 700}}>Карточка товара</MTypography>{' '}
            </MTableCell>
            {/* <MTableCell align="left">
              <MTypography sx={{fontWeight: 700}}>Цена</MTypography>
            </MTableCell> */}
            <MTableCell align="right">
              <MTypography sx={{fontWeight: 700}}>Действие</MTypography>
            </MTableCell>
          </MTableRow>
        </MTableHead>
        <MTableBody>
          {isLoadingProducts ? (
            <ProductRowLoader />
          ) : (
            products.map((product) => <ProductRow key={product.id} product={product} />)
          )}
          {loading && (
            <MTableRow>
              <MTableCell align="center" colSpan={3}>
                <div style={{display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center'}}>
                  <MTypography>Загрузка товаров...</MTypography>
                  <Loader />
                </div>
              </MTableCell>
            </MTableRow>
          )}
        </MTableBody>
      </MTable>
    </TableContainer>
  );
});
