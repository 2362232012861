import {createContext} from 'react';

import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';
import {TRepricerStrategy} from '@src/stores/repricer/types';

interface IStrategyContext {
  productsOnStrategy: TMpAccountItem[];
  strategy: TRepricerStrategy;
  deleteProductIds: number[];
  onSelectProduct: (product: TMpAccountItem) => void;
  onChangeStrategy: (strategy: TRepricerStrategy) => void;
  initialRepricerItems: TRepricerItem[];
}

export const StrategyContext = createContext({} as IStrategyContext);
