import {FC} from 'react';
import {observer} from 'mobx-react-lite';

import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import {SearchIcon} from '@src/components/common/icons/components/search';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MInput} from '@src/components/redesign/mui-components/common/input/input';
import {MSelect} from '@src/components/redesign/mui-components/common/select/select';
import {TSelectedProductFilters} from '@src/pages/repricer/types/products';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './product-filters.s';

interface IProductFilters {
  onChangeSelectedFilters: (selectedFilters: TSelectedProductFilters) => void;
  selectedFilters: TSelectedProductFilters;
}

export const ProductFilters: FC<IProductFilters> = observer(({onChangeSelectedFilters, selectedFilters}) => {
  const {mpItems, productGroups} = useStore();

  const {classes} = useEmotionWrapper();
  return (
    <div className={classes.root}>
      <MInput
        autoComplete="off"
        placeholder="Найти товары по названию"
        sx={{width: 350}}
        fullWidth
        onChange={(e) => onChangeSelectedFilters({...selectedFilters, search: e.target.value})}
        value={selectedFilters.search}
        size="small"
        slotProps={{
          input: {
            endAdornment: <SearchIcon />,
          },
        }}
      />
      <FormControl>
        <InputLabel size="small">
          {selectedFilters.shops.length
            ? declOfNum(selectedFilters.shops.length, ['магазин', 'магазина', 'магазинов'])
            : 'Все магазины'}
        </InputLabel>
        <MSelect
          size="small"
          multiple
          isClearable
          sx={{width: 180}}
          value={selectedFilters.shops}
          clearFunction={() => onChangeSelectedFilters({...selectedFilters, shops: []})}
          onChange={(e) => onChangeSelectedFilters({...selectedFilters, shops: e.target.value as number[]})}
          input={
            <OutlinedInput
              label={
                selectedFilters.shops.length
                  ? declOfNum(selectedFilters.shops.length, ['магазин', 'магазина', 'магазинов'])
                  : 'Все магазины'
              }
            />
          }
        >
          {mpItems.filters.shops.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </MSelect>
      </FormControl>
      <FormControl>
        <InputLabel size="small">
          {selectedFilters.categories.length
            ? declOfNum(selectedFilters.categories.length, ['категория', 'категории', 'категорий'])
            : 'Категории'}
        </InputLabel>
        <MSelect
          size="small"
          multiple
          isClearable
          sx={{width: 180}}
          value={selectedFilters.categories}
          clearFunction={() => onChangeSelectedFilters({...selectedFilters, categories: []})}
          onChange={(e) => onChangeSelectedFilters({...selectedFilters, categories: e.target.value as number[]})}
          input={
            <OutlinedInput
              label={
                selectedFilters.categories.length
                  ? declOfNum(selectedFilters.categories.length, ['категория', 'категории', 'категорий'])
                  : 'Магазины'
              }
            />
          }
        >
          {mpItems.filters.categories.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </MSelect>
      </FormControl>
      <FormControl>
        <InputLabel size="small">
          {selectedFilters.vendors.length
            ? declOfNum(selectedFilters.vendors.length, ['Бренд', 'Бренда', 'Брендов'])
            : 'Бренды'}
        </InputLabel>
        <MSelect
          size="small"
          multiple
          isClearable
          sx={{width: 180}}
          value={selectedFilters.vendors}
          clearFunction={() => onChangeSelectedFilters({...selectedFilters, vendors: []})}
          onChange={(e) => onChangeSelectedFilters({...selectedFilters, vendors: e.target.value as number[]})}
          input={
            <OutlinedInput
              label={
                selectedFilters.vendors.length
                  ? declOfNum(selectedFilters.vendors.length, ['Бренд', 'Бренды', 'Брендов'])
                  : 'Бренды'
              }
            />
          }
        >
          {mpItems.filters.vendors.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </MSelect>
      </FormControl>
      <FormControl>
        <InputLabel size="small">
          {selectedFilters.groups.length
            ? declOfNum(selectedFilters.groups.length, ['Группы', 'Группы', 'Групп'])
            : 'Группы'}
        </InputLabel>
        <MSelect
          size="small"
          multiple
          isClearable
          sx={{width: 180}}
          value={selectedFilters.groups}
          clearFunction={() => onChangeSelectedFilters({...selectedFilters, groups: []})}
          onChange={(e) => onChangeSelectedFilters({...selectedFilters, groups: e.target.value as number[]})}
          input={
            <OutlinedInput
              label={
                selectedFilters.groups.length
                  ? declOfNum(selectedFilters.groups.length, ['Группы', 'Группы', 'Групп'])
                  : 'Группы'
              }
            />
          }
        >
          {productGroups.list.length ? (
            productGroups.list.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem sx={{fontSize: 14}} disabled value="">
              <em>Нет созданных групп</em>
            </MenuItem>
          )}
        </MSelect>
      </FormControl>
      {selectedFilters.search.length ||
      selectedFilters.shops.length ||
      selectedFilters.categories.length ||
      selectedFilters.vendors.length ||
      selectedFilters.groups.length ? (
        <MButton
          variant="text"
          onClick={() =>
            onChangeSelectedFilters({
              shops: [],
              categories: [],
              vendors: [],
              groups: [],
              search: '',
            })
          }
        >
          Сбросить фильтры
        </MButton>
      ) : null}
    </div>
  );
});
