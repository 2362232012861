import {type FC} from 'react';

import {MTable} from '@src/components/redesign/mui-components/common/table/table';
import {MTableBody} from '@src/components/redesign/mui-components/common/table/table-body';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableContainer} from '@src/components/redesign/mui-components/common/table/table-container';
import {MTableHead} from '@src/components/redesign/mui-components/common/table/table-head';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {TRepricerItemCompetitorPrice} from '@src/pages/repricer/types/repricer-item';
import {insertSeparatorToNumberOrString} from '@src/shared/utils/insert-separator-to-number-or-string';

import {useEmotionWrapper} from './competitors-table.s';

interface ICompetitorsTableProps {
  competitors: TRepricerItemCompetitorPrice[];
}

export const CompetitorsTable: FC<ICompetitorsTableProps> = ({competitors}) => {
  const {classes} = useEmotionWrapper();

  return (
    <MTableContainer>
      <MTable size="small" classes={{root: classes.tableRoot}}>
        <MTableHead>
          <MTableRow>
            <MTableCell>Магазин</MTableCell>
            <MTableCell align="center">Рейтинг</MTableCell>
            <MTableCell align="center">Цена, ₽</MTableCell>
            <MTableCell align="right">Позиция в выдаче</MTableCell>
          </MTableRow>
        </MTableHead>
        <MTableBody>
          {competitors.map((competitor) => {
            return (
              <MTableRow key={competitor.createdAt + competitor.price}>
                <MTableCell>{competitor.shop.name}</MTableCell>
                <MTableCell align="center">{competitor.shop.rating}</MTableCell>
                <MTableCell align="center">{insertSeparatorToNumberOrString(competitor.price)}</MTableCell>
                <MTableCell align="right">{competitor.position}</MTableCell>
              </MTableRow>
            );
          })}
        </MTableBody>
      </MTable>
    </MTableContainer>
  );
};
