import {type FC} from 'react';
import {observer} from 'mobx-react-lite';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import Drawer from '@mui/material/Drawer/Drawer';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {useStore} from '@src/stores';

import {CompetitorsTable} from './components/competitors-table/competitors-table';
import {PriceBlock} from './components/price-block/price-block';

import {useEmotionWrapper} from './repricer-item-details.s';

interface IRepricerItemDetails {
  isOpenAnalytics: boolean;
  setIsOpenAnalytics: (isOpen: boolean) => void;
  product: TRepricerItem | null;
}

export const RepricerItemDetails: FC<IRepricerItemDetails> = observer(
  ({isOpenAnalytics, setIsOpenAnalytics, product}) => {
    const {classes} = useEmotionWrapper();
    const {mpAccounts} = useStore();

    const priceLogsDashboardUrl = () => {
      if (mpAccounts.current?.uuidSk && product?.sku) {
        const url = new URL(import.meta.env.WEB_APP_YANDEX_DATA_LENS_PRICE_LOG_DASHBOARD_URL);

        url.searchParams.append('token', mpAccounts.current?.uuidSk);
        url.searchParams.append('sku', product.sku);

        return url.toString();
      }
    };

    if (product === null) return null;

    return (
      <Backdrop sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})} open={isOpenAnalytics}>
        <Drawer
          anchor="right"
          open={isOpenAnalytics}
          onClose={() => setIsOpenAnalytics(false)}
          classes={{paperAnchorRight: classes.paperRoot}}
        >
          <div className={classes.header}>
            <MTypography className={classes.productName}>{product.name}</MTypography>
            <MIconButton onClick={() => setIsOpenAnalytics(false)}>
              <CloseIcon />
            </MIconButton>
          </div>
          <div className={classes.content}>
            {product.attractivePrice || product.moderateAttractivePrice ? (
              <div className={classes.detailsItem}>
                <MTypography className={classes.blockTitle}>Рекомендации Яндекса</MTypography>
                <div className={classes.priceContent}>
                  <PriceBlock price={product.attractivePrice} description={'Привлекательная цена'} />
                  <PriceBlock price={product.moderateAttractivePrice} description={'Умеренно привлекательная цена'} />
                </div>
              </div>
            ) : null}
            <div className={classes.detailsItem}>
              <MTypography className={classes.blockTitle}>График изменения цены</MTypography>
              <div className={classes.priceContent}>
                <iframe className={classes.priceLogIframe} src={priceLogsDashboardUrl()} />
              </div>
            </div>
            {product.competitorsPrices.length > 0 ? (
              <div className={classes.detailsItem}>
                <MTypography className={classes.blockTitle}>Анализ конкурентов</MTypography>
                <CompetitorsTable competitors={product.competitorsPrices} />
              </div>
            ) : null}
          </div>
        </Drawer>
      </Backdrop>
    );
  }
);
