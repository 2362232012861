import {FC, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';

import CloseIcon from '@mui/icons-material/Close';
import {useHideScrollbar} from '@src/hooks/useHideScrollbar';

import {MIconButton} from '../mui-components/common/icon-button/icon-button';
import {MTypography} from '../mui-components/common/typography/typography';

import {useEmotionWrapper} from './slide-up-modal.s';

interface ISlideUpModal {
  opened: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
}

export const SlideUpModal: FC<ISlideUpModal> = ({opened, onClose, title, children}) => {
  const {classes} = useEmotionWrapper();
  const modalContentRef = useRef(null);

  const animation = {
    enter: classes.modalEnter,
    enterActive: classes.modalEnterActive,
    exit: classes.modalExit,
    exitActive: classes.modalExitActive,
  };

  //hide body scrollbar
  useHideScrollbar(opened);

  return (
    <CSSTransition
      in={opened}
      nodeRef={modalContentRef}
      timeout={250}
      mountOnEnter
      unmountOnExit
      classNames={animation}
    >
      <div className={classes.root} ref={modalContentRef}>
        <div className={classes.header}>
          <MTypography sx={{fontWeight: 700, fontSize: 32}}>{title}</MTypography>
          <MIconButton onClick={onClose} size="large">
            <CloseIcon sx={{fontSize: 48}} />
          </MIconButton>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </CSSTransition>
  );
};
