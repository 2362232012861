import {FC, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import Paper from '@mui/material/Paper/Paper';
import TextField from '@mui/material/TextField/TextField';
import {Tooltip} from '@src/components/common/tooltip/tooltip';
import {MSelect} from '@src/components/redesign/mui-components/common/select/select';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {NEW_STRATEGY_ID} from '@src/pages/repricer/consts';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {getRouteRepricer} from '@src/shared/const/router';
import {REPRICER_STRATEGY, REPRICER_STRATEGY_SELECT_OPTIONS} from '@src/shared/enums/repricer-strategy';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {useStore} from '@src/stores';
import {TRepricerStrategy} from '@src/stores/repricer/types';

import {useStrategy} from '../../../hooks/useStrategy';

import {useEmotionWrapper} from './strategy-settings.s';

interface IStrategySettingsProps {
  collectionPriceUpdateFunctions: Array<() => Promise<void>>;
}

export const StrategySettings: FC<IStrategySettingsProps> = observer(({collectionPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();
  const {mpAccounts, repricer} = useStore();
  const {strategy, onChangeStrategy, productsOnStrategy, deleteProductIds, initialRepricerItems} = useStrategy();

  const [isLoading, setIsLoading] = useState(false);

  const disabledCreateAndSaveButton = !strategy?.name || !strategy?.type;

  const handleChangeStrategy = (key: keyof TRepricerStrategy, value: TRepricerStrategy[keyof TRepricerStrategy]) => {
    if (strategy !== null) {
      onChangeStrategy({...strategy, [key]: value});
    }
  };

  const formattingPricePct = (pct: string | null | undefined) => {
    if (pct === null || pct === undefined) {
      return null;
    }
    if (pct.toString().length > 0) {
      return stringToNumberWithDot(pct);
    }
    return null;
  };

  const createStrategyHandler = async () => {
    if (mpAccounts.current?.id) {
      try {
        const {type, name, params, isAuto, minPricePct, maxPricePct, enabled} = strategy;
        const itemIds = productsOnStrategy.map((p) => p.id);
        const newStrategyPayload = {
          type,
          name,
          enabled,
          params,
          isAuto,
          minPricePct: formattingPricePct(minPricePct?.toString()),
          maxPricePct: formattingPricePct(maxPricePct?.toString()),
          marketPlaceAccountId: mpAccounts.current?.id,
        };

        const createdStrategyId = await repricer.createStrategy(newStrategyPayload);

        if (createdStrategyId) {
          await repricer.addItemsToStrategy({
            strategyId: createdStrategyId.strategyId,
            itemIds,
          });
        }

        await repricer.getStrategies(mpAccounts.current?.id);

        toast.success('Стратегия успешно создана');
      } catch (e) {
        throw new Error();
      }
    }
  };

  const updateStrategyHandler = async () => {
    if (mpAccounts.current?.id && strategy.id) {
      try {
        const uniqArr = productsOnStrategy.filter(
          //@ts-ignore
          (product) => !initialRepricerItems.some((initialProduct) => initialProduct.id === product.id)
        );

        await repricer.updateStrategy(
          {
            ...strategy,
            minPricePct: formattingPricePct(strategy.minPricePct?.toString()),
            maxPricePct: formattingPricePct(strategy.maxPricePct?.toString()),
          },
          mpAccounts.current?.id
        );

        if (uniqArr.length) {
          await repricer.addItemsToStrategy({
            strategyId: strategy.id,
            itemIds: uniqArr.map((item) => item.id),
          });
        }

        if (deleteProductIds.length) {
          await repricer.deleteItemsFromStrategy({
            strategyId: strategy.id,
            ids: deleteProductIds,
          });
        }
        toast.success('Стратегия успешно обновлена');
      } catch (e) {
        throw new Error();
      }
    }
  };

  const onClickSave = async () => {
    setIsLoading(true);

    try {
      if (strategy?.id !== NEW_STRATEGY_ID) {
        await updateStrategyHandler();
      } else {
        await createStrategyHandler();
      }

      collectionPriceUpdateFunctions.forEach(async (updateFunction) => {
        await updateFunction();
      });

      if (mpAccounts.current?.id) {
        await repricer.getStrategies(mpAccounts.current?.id);
      }
      navigate(getRouteRepricer());
    } catch (e) {
      null;
    } finally {
      setIsLoading(false);
    }
  };

  const validateMinPricePct = (percent: string | number | null) => {
    const minPct = stringToNumberWithDot(percent || 0);

    if (minPct > 100) {
      return true;
    }

    return false;
  };

  const validateMaxPricePct = (percent: string | number | null) => {
    const maxPct = stringToNumberWithDot(percent || 0);

    if ((maxPct > 999 || maxPct < 100) && percent !== null && percent !== '') {
      return true;
    }

    return false;
  };

  const validatePositionOnPlaceStrategy = () => {
    if (strategy?.type === REPRICER_STRATEGY.PLACE) {
      if (stringToNumberWithDot(strategy?.params.place || 0) > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <Paper elevation={1} className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.itemsBlock}>
          <MTypography className={classes.itemTitle}>Название стратегии</MTypography>
          <TextField
            error={repricer.list.some((item) => item.name === strategy?.name && item.id !== strategy?.id) && !isLoading}
            helperText={
              repricer.list.some((item) => item.name === strategy?.name && item.id !== strategy?.id) && !isLoading
                ? `Стратегия с таким названием уже существует`
                : ''
            }
            required
            fullWidth
            size="small"
            name="name"
            label="Название"
            variant="outlined"
            onChange={(e) => handleChangeStrategy('name', e.target.value)}
            value={strategy?.name}
          />
        </div>
        <div className={classes.item}>
          <MTypography className={classes.itemTitle}>Стратегия</MTypography>
          <div className={classes.strategyTypeBlock}>
            <MSelect
              required
              fullWidth
              size="small"
              name="type"
              variant="outlined"
              onChange={(e) => handleChangeStrategy('type', e.target.value as TRepricerStrategy['type'])}
              value={strategy?.type}
            >
              {REPRICER_STRATEGY_SELECT_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MSelect>
            {strategy?.type === REPRICER_STRATEGY.PLACE && (
              <TextField
                type="number"
                error={!validatePositionOnPlaceStrategy()}
                sx={{maxWidth: 85}}
                size="small"
                label="Позиция"
                onChange={(e) => handleChangeStrategy('params', {place: stringToNumberWithDot(e.target.value)})}
                value={strategy?.params.place?.toString() === '0' ? '' : strategy?.params.place?.toString()}
                placeholder="1"
              />
            )}
          </div>
        </div>
        <div className={classes.itemsBlock}>
          <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            <MTypography className={classes.itemTitle}>Дополнительно</MTypography>
            <Tooltip
              position="left"
              content="Вы можете указать Минимальные и Максимальные цены товаров в процентах от текущих цен. При этом процент Мин/Макс цены применится ко всем товарам в стратегии и изменит значения, которые были заданы ранее."
            >
              <InfoIcon fontSize="small" color="action" />
            </Tooltip>
          </div>

          <div className={classes.pricePctContainer}>
            <FormControl error={validateMinPricePct(strategy?.minPricePct)} size="small" variant="outlined">
              <FormHelperText>
                {validateMinPricePct(strategy?.minPricePct) ? 'От 0 до 100' : 'Мин. цена'}
              </FormHelperText>
              <OutlinedInput
                onChange={(e) => handleChangeStrategy('minPricePct', e.target.value)}
                value={strategy?.minPricePct?.toString()}
                placeholder="0"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl size="small" error={validateMaxPricePct(strategy?.maxPricePct)} variant="outlined">
              <FormHelperText>
                {validateMaxPricePct(strategy?.maxPricePct) ? 'От 100 до 999' : 'Макс. цена'}{' '}
              </FormHelperText>
              <OutlinedInput
                onChange={(e) => handleChangeStrategy('maxPricePct', e.target.value)}
                value={strategy?.maxPricePct?.toString()}
                placeholder="0"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
          </div>
        </div>
        <Tooltip
          destroy={productsOnStrategy.length > 0}
          content="Для создания стратегии необходимо выбрать хотя бы один товар"
        >
          <LoadingButton
            loading={isLoading}
            onClick={onClickSave}
            disabled={
              disabledCreateAndSaveButton ||
              validateMinPricePct(strategy?.minPricePct) ||
              validateMaxPricePct(strategy?.maxPricePct) ||
              (strategy.type === REPRICER_STRATEGY.PLACE && !validatePositionOnPlaceStrategy())
            }
            variant="contained"
            fullWidth
          >
            {strategy?.id === 0 ? 'Создать стратегию' : 'Сохранить стратегию'}
          </LoadingButton>
        </Tooltip>
        <MTypography align="justify" sx={{fontSize: 14, color: COLORS_REDESIGN.DARK_GREY}}>
          После добавления товаров в стратегию, сбор аналитических данных может занять до 4 часов.
        </MTypography>
      </div>
    </Paper>
  );
});
