import {useState} from 'react';

import {ProductSettingsTable} from './repricer-item-table/product-settings-table/product-settings-table';
import {StrategySettings} from './strategy-settings/strategy-settings';

export const StrategyDetails = () => {
  const [collectionPriceUpdateFunctions, setCollectionPriceUpdateFunctions] = useState<Array<() => Promise<void>>>([]);

  const collectPriceUpdateFunctions = (updateFunction: () => Promise<void>) => {
    setCollectionPriceUpdateFunctions((prev) => [...prev, updateFunction]);
  };

  return (
    <div style={{display: 'flex', gap: 20, maxWidth: '100%', justifyContent: 'center'}}>
      <div style={{display: 'flex', gap: 20, width: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
        <ProductSettingsTable collectPriceUpdateFunctions={collectPriceUpdateFunctions} />
        <StrategySettings collectionPriceUpdateFunctions={collectionPriceUpdateFunctions} />
      </div>
    </div>
  );
};
