import {FC, ReactNode} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {CloseIcon} from '@src/components/common/icons';

import {useMount} from './hooks/useMount';
import {Layout} from './layout/layout';
import {IconButton, Portal, Typography} from '../..';

import {useEmotionWrapper} from './modal.s';

interface IModal {
  opened: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | JSX.Element;
  actionButton?: JSX.Element;
  isCloseOnClickOverlay?: boolean;
  icon?: JSX.Element;
}

export const Modal: FC<IModal> = ({
  opened,
  onClose,
  children,
  title,
  actionButton,
  icon,
  isCloseOnClickOverlay = true,
}) => {
  const {classes} = useEmotionWrapper();
  const {mounted} = useMount({opened});

  if (!mounted || !opened) {
    return null;
  }

  return (
    <Portal>
      <Layout onClose={isCloseOnClickOverlay ? onClose : () => {}} opened={opened}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {icon}
            <Typography size={20} weight={700} color={COLORS.DARK_100}>
              {title}
            </Typography>
          </div>
          <div className={classes.actionButtonBlock}>
            {actionButton}
            <IconButton size={16} onClick={onClose}>
              <CloseIcon color={COLORS.BLACK_100} />
            </IconButton>
          </div>
        </div>
        <div style={{paddingBottom: 3}} className={classes.contentContainer}>
          {children}
        </div>
      </Layout>
    </Portal>
  );
};
