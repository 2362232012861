import {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Typography} from '@mui/material';
import {NoImageIcon} from '@src/components/common/icons';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {NEW_STRATEGY_ID} from '@src/pages/repricer/consts';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {useStrategy} from '../../../hooks/useStrategy';

import {useEmotionWrapper} from './product-row.s';
interface IProductRow {
  product: TMpAccountItem;
}

export const ProductRow: FC<IProductRow> = observer(({product}) => {
  const {classes} = useEmotionWrapper();
  const {repricer} = useStore();

  const {onSelectProduct, productsOnStrategy, strategy} = useStrategy();
  const [isErrorLoadImage, setIsErrorLoadImage] = useState(false);

  const copiedMap = new Map(
    [...repricer.summaryRepricerItemIds].map(([key, value]) => [key, JSON.parse(JSON.stringify(value))])
  );

  const isUseAnotherStrategy = useMemo(() => {
    copiedMap.delete(strategy.id.toString());

    const isUseAnotherStrategy = [...copiedMap.values()].some((value) => value.itemIds.includes(product.id));

    return isUseAnotherStrategy;
  }, [copiedMap, strategy.id, product.id]);

  const isUseThisStrategy = productsOnStrategy.some((item) => item.id === product.id);

  const getAnotherStrategy = () => {
    copiedMap.delete(strategy.id.toString());
    let strategyId = NEW_STRATEGY_ID;

    copiedMap.forEach((value, key) => {
      if (value.itemIds.includes(product.id)) {
        strategyId = Number(key);
      }
    });
    const findStrategy = repricer.list.find((item) => item.id === strategyId);

    return findStrategy;
  };

  return (
    <>
      <MTableRow key={product.id}>
        <MTableCell className={classes.nameCell}>
          {isErrorLoadImage ? (
            <div className={classes.noImage}>
              <NoImageIcon color={COLORS_REDESIGN.DARK_GREY} size="32" />
            </div>
          ) : (
            <img
              onError={() => setIsErrorLoadImage(true)}
              loading="lazy"
              width={34}
              height={45}
              src={product.picture || ''}
            />
          )}
          <div className={classes.productInfo}>
            <MTypography className={classes.productName}> {product.name}</MTypography>
            <MTypography className={classes.productSku}>
              SKU: {product.sku} · {product.category}
            </MTypography>
          </div>
        </MTableCell>
        {/* <MTableCell align="left">
          <MTypography className={classes.price}>{insertSeparatorToNumberOrString(product.price)} ₽</MTypography>{' '}
        </MTableCell> */}
        <MTableCell align="right">
          {isUseAnotherStrategy ? (
            <div className={classes.actionsCellContainer}>
              <div className={classes.isUseItem}>
                <Typography sx={{fontSize: 12, color: COLORS_REDESIGN.DARK_GREY}}>Уже в стратегии</Typography>
                <Typography sx={{fontSize: 12, fontWeight: 600, color: COLORS_REDESIGN.DARK_GREY}}>
                  {getAnotherStrategy()?.name}
                </Typography>
              </div>
            </div>
          ) : (
            <MButton onClick={() => onSelectProduct(product)} variant={isUseThisStrategy ? 'outlined' : 'contained'}>
              {isUseThisStrategy ? 'Удалить' : 'Добавить'}
            </MButton>
          )}
        </MTableCell>
      </MTableRow>
    </>
  );
});
