import {FC, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import {Button, Input, Link, Tooltip, Typography} from '@src/components';
import {COLORS} from '@src/shared/const/appPalette';
import {getRouteSettings} from '@src/shared/const/router';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {useStore} from '@src/stores';
import {TMapShopType, TProduct, TUpdateMaxPrice, TUpdateMinPrice, TUpdatePrice} from './types';

import {useEmotionWrapper} from './product-price-updater-tooltip.s';

interface IProductPriceUpdaterTooltip {
  isHideMargin?: boolean;
  isRepricer?: boolean;
  isVisible?: boolean;
  product: TProduct;
  updateMinPrice: TUpdateMinPrice;
  updatePrice: TUpdatePrice;
  updateMaxPrice?: TUpdateMaxPrice;
  onCloseTooltip?: () => void;
  currentShopId?: number;
}

const initialProductShops: Map<number, TMapShopType> = new Map();

export const ProductPriceUpdaterTooltip: FC<IProductPriceUpdaterTooltip> = observer(
  ({
    product,
    isRepricer = false,
    updateMinPrice,
    updatePrice,
    isVisible,
    onCloseTooltip,
    currentShopId,
    isHideMargin = false,
    updateMaxPrice,
  }) => {
    const {classes} = useEmotionWrapper(isRepricer);
    const {mpAccounts, mpItems} = useStore();
    const [productShops, setProductShops] = useState<Map<number, TMapShopType>>(initialProductShops);
    const [basePrice, setBasePrice] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const newProductShops = new Map();
      product.shops.forEach((shop) => {
        newProductShops.set(shop.shopId, {
          ...shop,
        });
      });
      setProductShops(newProductShops);
      setBasePrice(product.price.toString());
    }, [JSON.stringify(product.shops), product.itemId, isVisible]);

    const isNotChangedShopData = useMemo(() => {
      const isNotChangedPrice = product.shops.every(
        (shop) => shop.price === stringToNumberWithDot(productShops.get(shop.shopId)?.price || 0)
      );
      const isNotChangedMinPrice = product.shops.every(
        (shop) => shop.minPrice === stringToNumberWithDot(productShops.get(shop.shopId)?.minPrice || 0)
      );
      const isNotChangedMaxPrice = product.shops.every(
        (shop) =>
          stringToNumberWithDot(shop.maxPrice || 0) ===
          stringToNumberWithDot(productShops.get(shop.shopId)?.maxPrice || 0)
      );
      const isChangedMargin = product.shops.every(
        (shop) =>
          stringToNumberWithDot(shop.margin || 0) === stringToNumberWithDot(productShops.get(shop.shopId)?.margin || 0)
      );

      const isNotChangedProductPrice = stringToNumberWithDot(product.price) === stringToNumberWithDot(basePrice);

      return (
        isNotChangedPrice && isNotChangedMinPrice && isChangedMargin && isNotChangedProductPrice && isNotChangedMaxPrice
      );
    }, [product.shops, productShops, basePrice]);

    const onSaveUpdatedPrices = async () => {
      setIsLoading(true);
      const requests: Promise<void>[] = [];

      const formattedProductPrice = stringToNumberWithDot(product.price);

      productShops.forEach((shop) => {
        const findShopInStore = product.shops.find((s) => s.shopId === shop.shopId);
        const formattedShopPrice = stringToNumberWithDot(shop.price);
        const formattedMinPrice = shop.minPrice !== undefined ? stringToNumberWithDot(shop.minPrice) : undefined;
        const formattedMaxPrice = shop.maxPrice ? stringToNumberWithDot(shop.maxPrice) : undefined;
        const formattedMargin = shop.margin !== null ? stringToNumberWithDot(shop.margin) : undefined;

        const isMarginDifferentFromShopMargin = findShopInStore && formattedMargin !== findShopInStore.margin;
        const isPriceDifferentFromShopPrice = findShopInStore && formattedShopPrice !== findShopInStore.price;
        const isMinPriceDifferentFromShopMinPrice =
          isRepricer &&
          findShopInStore &&
          formattedMinPrice !== undefined &&
          formattedMinPrice !== findShopInStore.minPrice;
        const isMaxPriceDifferentFromShopMinPrice =
          isRepricer &&
          findShopInStore &&
          formattedMaxPrice !== undefined &&
          formattedMaxPrice !== findShopInStore.maxPrice;

        if (isPriceDifferentFromShopPrice && formattedShopPrice > 0) {
          requests.push(updatePrice(product.itemId, formattedShopPrice * 100, shop.shopId));
        }

        if (isMinPriceDifferentFromShopMinPrice) {
          requests.push(updateMinPrice(product.itemId, formattedMinPrice * 100));
        }

        if (isMaxPriceDifferentFromShopMinPrice && updateMaxPrice) {
          requests.push(updateMaxPrice(product.itemId, formattedMaxPrice * 100));
        }

        if (isMarginDifferentFromShopMargin && formattedMargin !== undefined) {
          requests.push(
            mpItems.updateItemMarginInShop({
              itemId: product.itemId,
              shopId: shop.shopId,
              margin: formattedMargin,
            })
          );
        }
      });

      if (formattedProductPrice !== stringToNumberWithDot(basePrice) && stringToNumberWithDot(basePrice) > 0) {
        requests.push(mpItems.updateItemPrice(product.itemId, stringToNumberWithDot(basePrice) * 100));
      }

      Promise.all(requests)
        .then(() => {
          if (isRepricer) {
            //FIXME: DELETE REPRICER MOEDE
            // mpAccounts.current?.id &&
            //   priceMonitoring.getRepricerItemsList({
            //     marketPlaceAccountId: mpAccounts.current?.id,
            //     isHiddenLoading: true,
            //   });
            null;
          } else {
            mpItems.getItemsByAccountId({marketPlaceAccountId: mpAccounts.current?.id, isHiddenLoading: true});
          }
        })
        .then(() => {
          onCloseTooltip && onCloseTooltip();
          requests.length > 0 && toast.success('Данные обновлены');
        })
        .catch(() => toast.error('Не удалось обновить цены'))
        .finally(() => setIsLoading(false));
    };

    const onChangePrice = (shopId: number, price: string) => {
      const newProductShops = new Map([...productShops]);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, price: price});
      }

      setProductShops(newProductShops);
    };

    const onChangeMargin = (shopId: number, margin: string) => {
      const newProductShops = new Map(productShops);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, margin: margin});
      }

      setProductShops(newProductShops);
    };

    const onChangeMinPrice = (shopId: number, minPrice: string) => {
      const newProductShops = new Map(productShops);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, minPrice});
      }

      setProductShops(newProductShops);
    };

    const onChangeMaxPrice = (shopId: number, maxPrice: string) => {
      const newProductShops = new Map(productShops);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, maxPrice});
      }

      setProductShops(newProductShops);
    };

    const setBasePriceAllShops = () => {
      productShops.forEach((shop) => {
        onChangePrice(shop.shopId, product.price.toString());
      });
    };

    const shopPriceNotify = () => {
      let hasZeroPriceInAnyStore = false;

      if (isRepricer) {
        productShops.forEach((shop) => {
          const formattedShopPrice = stringToNumberWithDot(shop.price);

          if (formattedShopPrice === 0) {
            hasZeroPriceInAnyStore = true;
          }
        });
      }

      if (hasZeroPriceInAnyStore && isRepricer) {
        return (
          <>
            <Tooltip content="Установить базовую цену для товаров, у которых цена не указана в магазинах.">
              <Typography>
                Для работы мониторинга цен необходимо{' '}
                <span onClick={setBasePriceAllShops} className={classes.setShopsPriceButton}>
                  задать цены <br /> в магазинах
                </span>
              </Typography>
            </Tooltip>

            <br />
          </>
        );
      }
    };

    const renderShopRow = (s: TMapShopType) => {
      return (
        <tr key={s.shopId}>
          <td align="left">
            <Typography paragraph whiteSpace="nowrap" className={classes.shopName}>
              {s.shopName}
            </Typography>{' '}
          </td>
          {!mpAccounts.current?.onlyDefaultPriceEnabled ? (
            <td align={isRepricer ? 'center' : 'right'}>
              <Input
                placeholder={product.price.toString()}
                disabled={isLoading}
                width={80}
                onChange={(e) => {
                  onChangePrice(s.shopId, e.target.value);
                }}
                value={s.price}
              />
            </td>
          ) : null}
          {isRepricer ? (
            <td align="center">
              <Input
                placeholder={product.price.toString()}
                disabled={isLoading}
                width={80}
                onChange={(e) => {
                  onChangeMinPrice(s.shopId, e.target.value);
                }}
                value={s.minPrice}
              />
            </td>
          ) : null}
          {isRepricer ? (
            <td align="center">
              <Input
                placeholder="—"
                disabled={isLoading}
                width={80}
                onChange={(e) => {
                  onChangeMaxPrice(s.shopId, e.target.value);
                }}
                value={s.maxPrice?.toString()}
              />
            </td>
          ) : null}
          {!isHideMargin && (
            <td align="right">
              <Input
                placeholder={'0'}
                disabled={isLoading}
                width={80}
                onChange={(e) => {
                  onChangeMargin(s.shopId, e.target.value);
                }}
                value={s.margin ? s.margin : ''}
              />
            </td>
          )}
        </tr>
      );
    };

    const geTableBody = () => {
      if (currentShopId) {
        const currentShop = productShops.get(currentShopId);
        if (currentShop) {
          return renderShopRow(currentShop);
        }
      } else {
        return Array.from(productShops.values()).map((shop) => {
          return renderShopRow(shop);
        });
      }
    };

    return (
      <div className={classes.root}>
        <Typography paragraph whiteSpace="nowrap" className={classes.productName} color={COLORS.DARK_70}>
          {product.name}
        </Typography>
        <br />
        {shopPriceNotify()}
        <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10}}>
          <Typography size={14}>Базовая цена</Typography>
          <div style={{display: 'flex', alignItems: 'center', gap: 3}}>
            <Input width={70} value={basePrice} onChange={(e) => setBasePrice(e.target.value)} />
            <Typography size={14}>₽</Typography>
          </div>
        </div>
        {mpAccounts.current?.onlyDefaultPriceEnabled ? (
          <Link href={getRouteSettings()}>Начать работать с ценами в магазинах</Link>
        ) : null}
        <table className={classes.tableRoot}>
          <thead>
            <tr>
              <th align="left">Магазин</th>
              {!mpAccounts.current?.onlyDefaultPriceEnabled ? <th align="center">Цена, ₽</th> : null}
              {isRepricer ? <th align="center">Мин. цена, ₽</th> : null}
              {isRepricer ? <th align="center">Макс. цена, ₽</th> : null}
              {!isHideMargin && <th align={isRepricer ? 'center' : 'right'}>Маржинальность, %</th>}
            </tr>
          </thead>
          <tbody>{geTableBody()}</tbody>
        </table>
        <Button
          loading={isLoading}
          disabled={isNotChangedShopData || isLoading}
          onClick={onSaveUpdatedPrices}
          variant="outlined"
          color={COLORS.GREEN_100}
        >
          Сохранить
        </Button>
      </div>
    );
  }
);
