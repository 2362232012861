import {TRepricerStrategy} from '@src/stores/repricer/types';

type TFilterCriteria = {
  name?: string;
  type?: TRepricerStrategy['type'][];
  enabled?: TRepricerStrategy['enabled'][];
};

export function filterStrategies(items: TRepricerStrategy[], filterCriteria: TFilterCriteria) {
  return items.filter((item) => {
    const nameMatch = filterCriteria.name?.length
      ? item.name.toLowerCase().includes(filterCriteria.name.toLowerCase())
      : true;

    const strategyMatch = filterCriteria.type?.length ? filterCriteria.type.includes(item.type) : true;

    const enabledMatch = filterCriteria.enabled?.length ? filterCriteria.enabled.includes(item.enabled) : true;

    return nameMatch && strategyMatch && enabledMatch;
  });
}
